<template>
  <v-app v-cloak>
    <notifications group="notifications" :position="'top right'"></notifications>
    <core-view></core-view>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
